import React, { useEffect, useState } from "react";
import axios from "axios";

import { App, Button, Flex, Form as FormAntd, Input, Modal, Tabs } from "antd"; // Import Table from antd
// utils
import { BASE_URL } from "utils/constants";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  JsonSchemaItem,
  VehicleItem,
  VehicleJsonConfig,
} from "utils/interfaces";

import Form from "@rjsf/antd";
import { RegistryFieldsType, RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

/* CONF 1.0 */
import Conf10_uiSchema from 'Components/CONF_1_0_Modal/schema-json/car-config.uischema.json';
import CONF_1_0_Modal from "Components/CONF_1_0_Modal/CONF_1_0_Modal";

/* CONF 2.0 */
import Conf20_uiSchema from 'Components/CONF_2_0_Modal/schema-json/car-config.uischema.json';
import CONF_2_0_Modal from "Components/CONF_2_0_Modal/CONF_2_0_Modal";

interface Props {
  vehicle_name: string;
  schema_id: number;
  config_id: number;
  showModal: boolean;
  vehicle: VehicleItem;
  onClose: (loading:boolean) => void;
}

type VehicleJsonConfigResponse = {
    success: boolean,
    content: VehicleJsonConfig
}

type JsonSchemaResponse = {
    success: boolean,
    content: JsonSchemaItem
}

const downloadConfigBinary = (vehicleName: string, configId: number) => {
    axios({
        url: `${BASE_URL}/get_vehicle_binary/${configId}`, //your url
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        const filename = "CANNY_CONFIG_" + vehicleName + ".bin"

        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}

const EditVehicleConfigModal: React.FC<Props> = ({ vehicle_name, schema_id, config_id, showModal, onClose, vehicle }: Props) => {

    const { modal } = App.useApp();

    const [form] = FormAntd.useForm();

    const [jsonValid, setJsonValid] = useState<boolean>(true)

    const [loading, setLoading] = useState<boolean>(false);

    const vehicleJsonConfigQuery = useQuery({
        queryKey: ['vehicleJsonConfig', config_id, schema_id],
        queryFn: async () => {
            const res = await axios.get<VehicleJsonConfigResponse>(`${BASE_URL}/get_json_config/${config_id}`)
            // setJsonConfigData(JSON.stringify(res.data.content.json_config, undefined, 4))
            return res.data.content
        },
        enabled: schema_id !== 0 && config_id !== 0
    })

    const jsonSchemaQuery = useQuery({
        queryKey: ['jsonSchemaConfig', config_id, schema_id],
        queryFn: async () => {
            const res = await axios.get<JsonSchemaResponse>(`${BASE_URL}/get_json_schemas_base/${schema_id}`)
            return res.data.content
        },
        enabled: schema_id !== 0 && config_id !== 0
    })

    useEffect(() => {
        if (vehicleJsonConfigQuery.data && showModal){
            let data = JSON.stringify(vehicleJsonConfigQuery.data.json_config, undefined, 4)
            // setJsonConfigData(()=>data);
            form.setFieldValue("json_config", data);
            // console.log("Config data variable: ", data)
        }
    //     eslint-disable-next-line
    }, [vehicleJsonConfigQuery.data, showModal ])

    const queryClient = useQueryClient()

    const updateVehicleMutation = useMutation({
        mutationFn: (values: VehicleItem) => {
            return axios.post(`${BASE_URL}/update_json_config/${config_id}`, values, {
                headers: {
                // Overwrite Axios's automatically set Content-Type
                'Content-Type': 'application/json'
              }})
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['vehicleList'] }),

        onSettled: () => setLoading(false)
      })

    const onFinish = (values: VehicleItem) => {
        setLoading(true)
        console.log('Received values of form: ', JSON.parse(values.json_config as string));
        values.json_config = JSON.parse(values.json_config as string)
        // var formData = new FormData;
        // formData.append('json_config', JSON.parse(values.json_config))
        updateVehicleMutation.mutate(values, {
        onSuccess: () =>  onClose(loading),
        onError: (error) => {
            modal.error({
                title: 'Error adding vehicle',
                content: error.message,
            });
        }
        });
    };

    const configType = `${vehicleJsonConfigQuery.data && vehicleJsonConfigQuery.data.status}`
    const jsonSchemaName = `${jsonSchemaQuery.data && jsonSchemaQuery.data.version_schema_name}`

    const vehicleId = vehicleJsonConfigQuery.data?.vehicle_id
    const vehicleStatus = vehicleJsonConfigQuery.data?.status

    const validTest = vehicle.json_config_versions?.["CONF 1.0"].TEST.valid
    const validRelease = vehicle.json_config_versions?.["CONF 1.0"].RELEASE.valid
    const confFrom = "CONF 1.0"
    const confTo = "CONF 2.0"

    const getModalTitle = () => {
        return (<>
            <Flex justify="space-around">
                {`Edit JSON config | ${vehicle_name} | ${configType} | ${jsonSchemaName}`}
                <Button onClick={() => downloadConfigBinary(vehicle_name, config_id)} disabled={loading}>Download binary</Button>
                {(jsonSchemaName === confTo && vehicleStatus === 'TEST') && (<Button disabled={!validTest || loading} onClick={() => updateFromConf1()}>Update from CONF 1.0</Button>)}
                {(jsonSchemaName === confTo && vehicleStatus === 'RELEASE') && (<Button disabled={!validRelease || loading} onClick={() => updateFromConf1()}>Update from CONF 1.0</Button>)}
            </Flex>
        </>)
    }

    const updateFromConf1 = () => {
        axios
        .get<VehicleJsonConfigResponse>(`${BASE_URL}/migrate_conf/${confFrom}/${confTo}/${vehicleStatus}/${vehicleId}`)
        .then((response) => {
            let data = JSON.stringify(response.data.content, undefined, 4)
            form.setFieldValue("json_config", data);
            console.log('response', data)
            })
        .catch((error) => {
          console.error("Error fetching Canny Devices:", error);
        });
    }

    const selectJsonSchemaID = () => {
        if(jsonSchemaName === 'CONF 1.0') {
            return Conf10_uiSchema
        }
        else if(jsonSchemaName === 'CONF 2.0') {
            return Conf20_uiSchema
        }else{
            return undefined
        }
    }

    const Fields: RegistryFieldsType = {
        conf_1_0_modal: CONF_1_0_Modal,
        conf_2_0_modal: CONF_2_0_Modal,
    };


    return(
        <Modal
            forceRender={true}
            title={getModalTitle()}
            loading={vehicleJsonConfigQuery.isLoading || jsonSchemaQuery.isLoading || loading}
            open={showModal}
            okText={jsonValid ? "Update" : "JSON invalid"}
            onOk={() =>{
                form.submit();
            }}
            cancelText="Cancel"
            width={1000}
            okButtonProps={{
                autoFocus: false,
                htmlType: 'submit',
                disabled: !jsonValid,
                danger: !jsonValid
            }}
            onCancel={() => onClose(loading)}
            destroyOnClose
            maskClosable={!loading}
        >
            <Tabs>
                <Tabs.TabPane tab="JSON Config Control" key='general' forceRender>
                    <Form
                        schema={jsonSchemaQuery.data?.json_schema ? jsonSchemaQuery.data?.json_schema : {} as RJSFSchema}
                        validator={validator}
                        fields={Fields}
                        formData={vehicleJsonConfigQuery.data?.json_config ? vehicleJsonConfigQuery.data?.json_config : {}}
                        liveValidate
                        onChange={(e) => {
                            form.setFieldValue("json_config", JSON.stringify(e.formData, undefined, 4))
                            form.validateFields()
                        }}
                        uiSchema={selectJsonSchemaID()}
                        formContext={vehicleJsonConfigQuery.data?.json_config ? vehicleJsonConfigQuery.data?.json_config : {}}
                        />
                </Tabs.TabPane>
                <Tabs.TabPane tab="JSON Config" key='2' forceRender>
                    <FormAntd
                        form={form}
                        name="form_edit_json_config"
                        clearOnDestroy
                        onFinish={(values:VehicleItem) => onFinish(values)}
                    >
                        <FormAntd.Item
                            name="json_config"
                            initialValue={form.getFieldValue("json_config")}

                            rules={[
                                {
                                required: true,
                                validator: (_, value: string) =>{
                                        if(value) {
                                            try {
                                                JSON.parse(value);
                                                setJsonValid(true)
                                                return Promise.resolve()
                                            } catch (e) {
                                                setJsonValid(false)
                                                return Promise.reject(new Error('Invalid JSON'))
                                            }
                                        } else {
                                            setJsonValid(false)
                                            return Promise.reject(new Error('Please enter JSON Schema'))
                                        }
                                    }
                                },
                            ]}>
                            <Input.TextArea rows={30} onChange={(e:any) => {
                                form.setFieldValue("json_config",e.target.value)
                            }}/>
                        </FormAntd.Item>
                    </FormAntd>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    )
}
export default EditVehicleConfigModal;


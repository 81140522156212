import { Tabs, Dropdown, Button, Modal, Collapse, Input, Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { EditOutlined } from '@ant-design/icons';

const conditionItems = [
    { label: 'AND', key: 'and' },
    { label: 'OR', key: 'or' },
    { label: 'Rule', key: 'rule' },
];

let renderedGlobal: {[key:string]: boolean}= {}
let renderfinished: boolean = false
let renderrename: boolean = true

function RecursiveDropdown({
    depth = 0,
    condition,
    onDelete,
    onAdd,
    formContext,
    formData,
    tabKey,
    maxDepth
    }   :   {
    depth: number,
    condition: any,
    onDelete: () => void,
    onAdd: () => void,
    formContext: any,
    formData: any,
    tabKey:string,
    maxDepth:number
    })
{
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const [buttonText, setButtonText] = useState<string>('Select');
    const [childDropdowns, setChildDropdowns] = useState<{ [key: number]: JSX.Element[] }>({});
    const [ruleDescriptions, setRuleDescriptions] = useState<string[]>([]);

    useEffect(() => {
        renderedGlobal[tabKey] = false
        initialRenderFunction()
        // console.log('initial')
        // console.log('maxDepth', maxDepth)
        if(depth === maxDepth){
            renderedGlobal[tabKey] = true
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if(renderfinished){
            rebuildChildren();
            // console.log('rebuild')
        }
    }, [formContext]);// eslint-disable-line react-hooks/exhaustive-deps

    const rebuildChildren = () => {
        const newChildren: { [key: number]: JSX.Element[] } = {};

        if (condition.and || condition.or) {
            const conditionType = condition.and ? 'and' : 'or';
            const newDepth = depth + 1;

            const newChildComponents = condition[conditionType].map((subCondition: any, index: number) => {
                const newKey = `${newDepth}-${index}`;
                return (
                    <RecursiveDropdown
                        key={newKey}
                        depth={newDepth}
                        condition={subCondition}
                        onDelete={() => handleChildDelete(newDepth, newKey)}
                        onAdd={() => handleChildAdd(newDepth, true)}
                        formContext={formContext}
                        formData={formData}
                        tabKey={tabKey}
                        maxDepth={maxDepth}
                    />
                );
            });

            newChildren[newDepth] = newChildComponents;
        } else if (condition.rule !== undefined) {
            const descriptions = formContext.rules.map((rule: any) => rule.description);
            setRuleDescriptions(descriptions);
        }

        setChildDropdowns(newChildren);
    };

    const initialRenderFunction = () => {
        if (condition.and || condition.or) {
            const conditionType = condition.and ? 'and' : 'or';
            handleSelect({ key: conditionType });
            const newChildren = condition[conditionType].reduce((acc: { [key: number]: JSX.Element[] }, subCondition: any, index: number) => {
                const newDepth = depth + 1;
                if (!acc[newDepth]) acc[newDepth] = [];
                acc[newDepth].push(
                    <RecursiveDropdown
                        key={`${newDepth}-${index}`}
                        depth={newDepth}
                        condition={subCondition}
                        onDelete={() => handleChildDelete(newDepth, `${newDepth}-${index}`)}
                        onAdd={() => handleChildAdd(newDepth,true)}
                        formContext={formContext}
                        formData={formData}
                        tabKey={tabKey}
                        maxDepth={maxDepth}
                    />
                );
                return acc;
            }, {...childDropdowns});
            setChildDropdowns(newChildren);
        } else if (condition.rule !== undefined) {
            const ruleIndex = condition.rule;
            const descriptions = formContext.rules.map((rule: any) => rule.description);
            setRuleDescriptions(descriptions);

            handleSelect({ key: 'rule' });
            handleRuleSelect({key: descriptions[ruleIndex]});
        }
    };

    const handleSelect = ({ key }: { key: string }) => {
        setSelectedKey(key);
        if (key === 'and' || key === 'or') {
            handleSelectUpdate({key:key})
            const childrenCount = childDropdowns[depth + 1]?.length ? childDropdowns[depth + 1].length : 0;

            if(childrenCount === 0){
                handleChildAdd(depth + 1,false)
                handleChildAdd(depth + 1,false)
            }
            if(childrenCount === 1 ){
                handleChildAdd(depth + 1,false)
            }

        } else if (key === 'rule') {
            const descriptions = formContext.rules.map((rule: any) => rule.description);
            setRuleDescriptions(descriptions);
            setChildDropdowns({})
            handleSelectUpdate({ key: key });

        }
    };

    const handleChildAdd = (newDepth: number, update:boolean) => {
        setChildDropdowns(prev => {
            const newKey = `${newDepth}-${(prev[newDepth] || []).length}`;

            return {
                ...prev,
                [newDepth]: [
                    ...(prev[newDepth] || []),
                <RecursiveDropdown
                    key={newKey}
                    depth={newDepth}
                    condition={{}}
                    onDelete={() => handleChildDelete(newDepth, newKey)}
                    onAdd={() => handleChildAdd(newDepth,true)}
                    formContext={formContext}
                    formData={formData}
                    tabKey={tabKey}
                    maxDepth={maxDepth}
                />
            ]
        }});
        if(update){
            handleChildAddUpdate()
        }
    };

    const handleChildDelete = (childDepth: number, childKey: string) => {
        setChildDropdowns(prev => {
            const updated = { ...prev };
            const targetCondition = condition;

            if (targetCondition.and) {
                targetCondition.and = targetCondition.and.filter((_:any, index:any) => `${childDepth}-${index}` !== childKey);
            } else if (targetCondition.or) {
                targetCondition.or = targetCondition.or.filter((_:any, index:any) => `${childDepth}-${index}` !== childKey);
            }

            updated[childDepth] = updated[childDepth]?.filter(child => child.key !== childKey) || [];

            if (updated[childDepth].length === 0) {
                delete updated[childDepth];
                formData.onChange(updated);
            }

            const mainCondition = formData[tabKey];
            const updatedCondition = updateNestedCondition(mainCondition, condition, targetCondition, 'handleChildDelete');
            formData.onChange(updatedCondition);

            return updated;
        });}

    const handleRuleSelect = ({ key }: { key: string }) => {
        setButtonText(key)
            handleRuleSelectUpdate({key:key})
    }

    const updateNestedCondition = (currentCondition: any, targetCondition: any, newValue: any, useLocation: string): any => {
        if(useLocation === 'handleRuleSelect' && currentCondition === targetCondition){
                return { ...currentCondition, rule: newValue };
        }
        if(useLocation === 'handleSelect'&& currentCondition === targetCondition) {
                return  newValue;
        }
        if (useLocation === 'handleChildAdd' && currentCondition === targetCondition) {
            if (currentCondition.and) {
                const updatedAnd = [...currentCondition.and, {}];
                return { ...currentCondition, and: updatedAnd };
            } else if (currentCondition.or) {
                const updatedOr = [...currentCondition.or, {}];
                return { ...currentCondition, or: updatedOr };
            }
        }
        if (useLocation === 'handleChildDelete' && currentCondition === targetCondition) {
            if (currentCondition.and) {
                const updatedAnd = currentCondition.and.filter((subCondition: any) => subCondition !== targetCondition);
                return { ...currentCondition, and: updatedAnd };
            } else if (currentCondition.or) {
                const updatedOr = currentCondition.or.filter((subCondition: any) => subCondition !== targetCondition);
                return { ...currentCondition, or: updatedOr };
            }
        }

        if (currentCondition.and) {
            return {
                ...currentCondition,
                and: currentCondition.and.map((subCondition: any) =>
                    updateNestedCondition(subCondition, targetCondition, newValue, useLocation)
                ),
            };
        }

        if (currentCondition.or) {
            return {
                ...currentCondition,
                or: currentCondition.or.map((subCondition: any) =>
                    updateNestedCondition(subCondition, targetCondition, newValue, useLocation)
                ),
            };
        }

        return currentCondition;
    };

    const handleSelectUpdate = ({ key }: { key: string }) => {
        const updatedValue = { [key]: [{}, {}] };
        const mainCondition = formData[tabKey]
        const updatedCondition = updateNestedCondition(mainCondition, condition, updatedValue, 'handleSelect');
        formData.onChange(updatedCondition);
    };

    const handleChildAddUpdate = () => {
        let updatedValue = {};
        if (condition.and) {
            updatedValue = { and: [...condition.and, {}] };
        } else if (condition.or) {
            updatedValue = { or: [...condition.or, {}] };
        }

        const mainCondition = formData[tabKey];
        const updatedCondition = updateNestedCondition(mainCondition, condition, updatedValue, 'handleChildAdd');
        formData.onChange(updatedCondition);

    }

    const handleRuleSelectUpdate = ({ key }: { key: string }) => {
        const ruleIndex = formContext.rules.findIndex((rule: any) => rule.description === key);
        // console.log(ruleIndex)

        const mainCondition = formData[tabKey]

        if (ruleIndex !== -1) {
            const updatedCondition = updateNestedCondition(mainCondition, condition, ruleIndex, 'handleRuleSelect');

            // console.log('updatedCondition',updatedCondition)
            formData.onChange(updatedCondition);
        }
    }

    const getButtonType = (ruleType: string | null) => {
        switch (ruleType) {
            case 'and': return 'blue';
            case 'or': return 'cyan';
            case 'rule': return 'pink';
        }
    };

    return (
        <div style={{ marginLeft: depth * 20 }}>
            <Dropdown
                menu={{ items: conditionItems, onClick: handleSelect }}
                trigger={['click']}
            >
                <Button color={getButtonType(selectedKey)} variant='outlined'>
                    {selectedKey ? selectedKey.toUpperCase() : 'Select'}
                </Button>
            </Dropdown>
            {depth > 0 && (
                <Button onClick={onDelete} color="red" variant='outlined'>-</Button>
            )}
            {depth > 0 && (
                <Button onClick={onAdd} color="green" variant='outlined'>+</Button>
            )}
            {selectedKey === 'rule' && ruleDescriptions.length > 0 && (
                <Dropdown
                    menu={{ items: ruleDescriptions.map(description => ({ label: description, key: description, onClick: handleRuleSelect })) }}
                    trigger={['click']}
                >
                    <Button color={getButtonType(selectedKey)} variant='outlined'>
                        {buttonText}
                    </Button>
                </Dropdown>
            )}
            <div>{Object.values(childDropdowns).map(children => children.map(child => child))}</div>
        </div>
    );
}

export default  function Conf20FunctionalityControlField({
    onChange,
    formData,
    formContext,
    }   :   {
    formData: any,
    onChange: (newFormData: any) => void,
    formContext: any
    })
{
    const [tabs, setTabs] = useState<any[]>([]);
    const [activeKey, setActiveKey] = useState<string | null>(null);
    const [panelHeight, setPanelHeight] = useState<number>(38);

    const [isEditing, setIsEditing] = useState(false);
    const [editingTabKey, setEditingTabKey] = useState<string | null>(null);
    const [newTabLabel, setNewTabLabel] = useState<string>('');


    useEffect(() => {
        // console.log('.......................................')
        renderfinished = false;
        if (formData) {
            setActiveKey(Object.keys(formData)[0]);
            setTabs(createTabItems(formData));
            // console.log('formData', formData)
            if(renderrename){
                renderrename = false
            }
        }
    }, [renderrename]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log('renderfinished',renderfinished)
        // console.log('renderedGlobal',renderedGlobal)
        // console.log('renderrename',renderrename)
        // console.log(tabs.length)
        // console.log(Object.keys(formData).length)
        if (formData &&  Object.values(renderedGlobal).every((value) => value === true) && (tabs.length === Object.keys(formData).length) && renderfinished === false && renderrename === false) {
            renderfinished = true;
        }
        if (formData &&  renderfinished) {
            setTabs(createTabItems(formData));
        }
    }, [formContext, tabs.length === 0, formData, renderrename]);// eslint-disable-line react-hooks/exhaustive-deps


    const removeDropdown = (key: string) => {
        setTabs(prevTabs => prevTabs.filter(tab => tab.key !== key));
        const updatedData: { [key: string]: any } = { ...formData };
        delete updatedData[key];
        // console.log('RemoveDropdown',updatedData)
        onChange(updatedData);
    };

    const addDropdown = (depth: number) => {
        setTabs(prevDropdowns => [
            ...prevDropdowns,
            <RecursiveDropdown
                key={prevDropdowns.length.toString()}
                depth={depth}
                condition={{}}
                onDelete={() => removeDropdown(prevDropdowns.length.toString())}
                onAdd={() => addDropdown(depth)}
                formContext={formContext}
                formData={formData}
                tabKey={prevDropdowns.length.toString()}
                maxDepth={0}
            />
        ]);
    };

    const calculateMaxDepth = (condition: any, depth: number = 0): number => {
        if (!condition) return depth;

        if (condition.and || condition.or) {
            const conditionType = condition.and ? condition.and : condition.or;
            return Math.max(...conditionType.map((subCondition: any) => calculateMaxDepth(subCondition, depth + 1)));
        }

        return depth;
    };

    const createTabItems = (data: any) => {
        return Object.keys(data).map(propertyName => {
            const condition = data[propertyName];

            return {
                key: propertyName,
                label: (
                    <span>
                        <>
                            <Tooltip title="Rename Tab">
                                    <EditOutlined
                                        onClick={() => showRenameModal(propertyName)}
                                        style={{ cursor: 'pointer', marginLeft: 5 }}
                                    />
                            </Tooltip>
                            {'  '}{propertyName}
                        </>
                    </span>
                ),
                children: (
                    <RecursiveDropdown
                        key={propertyName}
                        depth={0}
                        condition={condition}
                        onDelete={() => removeDropdown(propertyName)}
                        onAdd={() => addDropdown(0)}
                        formContext={formContext}
                        formData={{ ...formData, onChange: (newData: any) => {
                            const updatedData = { ...formData, [propertyName]: newData };
                            onChange(updatedData);
                        }}}
                        tabKey={propertyName}
                        maxDepth={calculateMaxDepth(condition)}
                    />
                ),
            };
        });
    };

    const handleAddTab = () => {
        const newKey = `Tab-${tabs.length + 1}`;
        const updatedData = { ...formData, [newKey]: {} };
        // console.log('HandleAddTab',updatedData)
        onChange(updatedData);
        const newTab = {
            key: newKey,
            label: (
                <span>
                    <>
                        <Tooltip title="Rename Tab">
                                <EditOutlined
                                    onClick={() => showRenameModal(newKey)}
                                    style={{ cursor: 'pointer', marginLeft: 5 }}
                                />
                        </Tooltip>
                        {'  '}{newKey}
                    </>
                </span>
            ),
            children: (
                <RecursiveDropdown
                    key={newKey}
                    depth={0}
                    condition={{}}
                    onDelete={() => removeDropdown(newKey)}
                    onAdd={() => addDropdown(0)}
                    formContext={formContext}
                    formData={formData}
                    tabKey={newKey}
                    maxDepth={0}
                />
            ),
        };
        setTabs([...tabs, newTab]);
        setActiveKey(newKey);
    };

    const handleDeleteTab = (key: string) => {
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete rule ${key} ?`,
            maskClosable: true,
            onOk: () => {
                const filteredTabs = tabs.filter(tab => tab.key !== key);
                setTabs(filteredTabs);
                if (filteredTabs.length > 0) {
                    setActiveKey(filteredTabs[0].key);
                } else {
                    setActiveKey(null);
                }
                removeDropdown(key);
                delete renderedGlobal[key]
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const clearAll = () => {
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete all rules ?`,
            maskClosable: true,
            onOk: () => {
                setTabs([]);
                const clearedData: { [key: string]: any } = {};
                onChange(clearedData);
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const handleClearSpecificTab = (key: string) => {
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete all data for rule ${key} ?`,
            maskClosable: true,
            onOk: () => {
                const updatedTabs = tabs.filter(tab => tab.key !== key);
                setTabs(updatedTabs);
                const updatedData: { [key: string]: any } = { ...formData };
                updatedData[key] = {};
                onChange(updatedData);
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const handleCollapseChange = (activeKeys: string | string[]) => {
        setPanelHeight(Array.isArray(activeKeys) && activeKeys.length > 0 ? 500 : 38);
    };

    const showRenameModal = (key: string) => {
        setEditingTabKey(key);
        setNewTabLabel(key);
        setIsEditing(true);
    };

    const handleRenameTab = () => {
        renderrename = true
        if (editingTabKey && newTabLabel) {
            const updatedTabs = tabs.map(tab => {
                if (tab.key === editingTabKey) {
                    tab.label = newTabLabel;
                }
                return tab;
            });
            setTabs(updatedTabs);
            const updatedData: { [key: string]: any } = { ...formData };
            updatedData[newTabLabel] = updatedData[editingTabKey];
            delete updatedData[editingTabKey];
            delete renderedGlobal[editingTabKey]
            onChange(updatedData);
        }
        setIsEditing(false);
        setEditingTabKey(null);
        setNewTabLabel('');
    };


    return (
        <>
            <Collapse onChange={handleCollapseChange}>
                <Collapse.Panel header="Functionality Config" key="1" style={{ height: `${panelHeight}px`, overflow: 'auto'}} forceRender>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top: `${panelHeight-100}px`, right: '10px' }}>
                        <Button onClick={clearAll} style={{ marginRight: '10px' }}>
                            Clear All
                        </Button>
                        <Button
                            onClick={() => handleClearSpecificTab(activeKey || '')}
                        >
                            Clear Active Tab
                        </Button>
                    </div>
                    <Tabs
                        type="editable-card"
                        onEdit={(key, action) => {
                            if (action === 'add') {
                                handleAddTab();
                            } else if (action === 'remove') {
                                handleDeleteTab(key as string);
                            }
                        }}
                        activeKey={activeKey || (tabs.length > 0 ? tabs[0].key : undefined)}
                        onChange={(key) => setActiveKey(key)}
                        addIcon="+"
                        items={tabs.map(tab => ({
                            ...tab,
                            forceRender: true,
                        }))}
                    />
                </Collapse.Panel>
            </Collapse>
            <Modal
                title="Rename Tab"
                open={isEditing}
                onOk={handleRenameTab}
                onCancel={() => {
                    setIsEditing(false);
                    setEditingTabKey(null);
                }}
            >
                <Input
                    value={newTabLabel}
                    onChange={(e) => setNewTabLabel(e.target.value)}
                    placeholder="Enter new tab name"
                />
            </Modal>
        </>
    );
}
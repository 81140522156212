import { Tabs, Form, Dropdown, Button, Space, Input, Checkbox, Modal, Collapse, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/menu/interface';
import { EditOutlined, ExclamationOutlined } from '@ant-design/icons';

export default  function Conf20CanMessageConfigField({
    onChange,
    formData,
    errorSchema
    }   :   {
    formData: any,
    onChange: (newFormData: any) => void,
    errorSchema: any
    })
{
    const [selectedConfig, setSelectedConfig] = useState<{ [key: string]: any }>({});

    const [editingTabId, setEditingTabId] = useState<string | null>(null);
    const [newTabName, setNewTabName] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const canIdFormatItems: ItemType[] = [
        { label: 'standard', key: 'standard' },
        { label: 'extended', key: 'extended' },
    ];

    useEffect(() => {
        if (formData) {
            setSelectedConfig(formData);
        }
    }, [formData]);

    const updateConfig = (canId: string, key: string, value: any) => {
        const updatedConfig = {
            ...selectedConfig,
            [canId]: {
                ...selectedConfig[canId],
                [key]: value,
            },
        };
        setSelectedConfig(updatedConfig);
        onChange(updatedConfig);
    };

    const handleAddTab = () => {
        let newCanId = `0x00`;
        while (selectedConfig[newCanId]) {
            newCanId = `0x${(Math.random() * 0xFFFFFF).toString(16).toUpperCase().padStart(2, '0')}`;
        }

        const newConfig = {
            ...selectedConfig,
            [newCanId]: {
                can_id_format: '',
                dlc: null,
                timeout_ms: null,
                brs: false,
            },
        };
        setSelectedConfig(newConfig);
        onChange(newConfig);
    };

    const handleDeleteTab = (canId: string) => {
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete tab ${canId} ?`,
            maskClosable: true,
            onOk: () => {
                const newConfig = { ...selectedConfig };
                delete newConfig[canId];
                setSelectedConfig(newConfig);
                onChange(newConfig);
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const showRenameModal = (canId: string) => {
        setEditingTabId(canId);
        setNewTabName(canId);
        setIsEditing(true);
    };

    const handleRenameTab = () => {
        if (editingTabId && newTabName) {
            if (newTabName !== editingTabId) {
                const updatedConfig = {
                    ...selectedConfig,
                    [newTabName]: { ...selectedConfig[editingTabId] },
                };
                delete updatedConfig[editingTabId];

                setSelectedConfig(updatedConfig);
                onChange(updatedConfig);
            }
        }
        setEditingTabId(null);
        setNewTabName('');
        setIsEditing(false);
    };

    return (
        <>
            <Collapse>
                <Collapse.Panel header="Can Message Config" key="1" >
                    <Tabs
                        type="editable-card"
                        onEdit={(key, action) => {
                            if (action === 'add') {
                                handleAddTab();
                            } else if (action === 'remove') {
                                handleDeleteTab(key as string);
                            }
                        }}
                        addIcon="+"
                    >
                        {Object.keys(selectedConfig).map((canId) =>{
                            const hasErrors = !!(errorSchema as any)?.can_message_config?.[canId];
                            const dlcErrors = !!(errorSchema as any)?.can_message_config?.[canId]?.dlc;
                            const timeoutErrors = !!(errorSchema as any)?.can_message_config?.[canId]?.timeout_ms;

                            return (
                                <Tabs.TabPane
                                    tab={
                                        <span>
                                            <>
                                                <Tooltip title="Rename Tab">
                                                        <EditOutlined
                                                            onClick={() => showRenameModal(canId)}
                                                            style={{ cursor: 'pointer', marginLeft: 5 }}
                                                        />
                                                </Tooltip>
                                                {'  '}{canId}{'  '}
                                                {hasErrors && (
                                                    <ExclamationOutlined
                                                        style={{ color: 'red', fontSize: 15 }}
                                                    />)
                                                }
                                            </>
                                        </span>
                                    }
                                    key={canId}
                                    closable
                                >
                                    <Form layout="horizontal">
                                        <Form.Item label="CAN ID Format">
                                            <Dropdown
                                                menu={{
                                                    items: canIdFormatItems,
                                                    selectable: true,
                                                    onClick: (e) => updateConfig(canId, 'can_id_format', e.key),
                                                }}
                                            >
                                                <Button>
                                                    <Space>{selectedConfig[canId]?.can_id_format || 'Select CAN ID Format'}</Space>
                                                </Button>
                                            </Dropdown>
                                        </Form.Item>
                                        <Form.Item label="DLC">
                                            <Input
                                                type="number"
                                                value={selectedConfig[canId]?.dlc}
                                                style={{ width: '10%', border: dlcErrors ? '1px solid red' : undefined }}
                                                onChange={(e) => updateConfig(canId, 'dlc', parseInt(e.target.value, 10))}
                                            />
                                        </Form.Item>
                                        <Form.Item label="BRS">
                                            <Checkbox
                                                checked={selectedConfig[canId]?.brs}
                                                onChange={(e) => updateConfig(canId, 'brs', e.target.checked)}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Timeout (ms)">
                                            <Input
                                                type="number"
                                                value={selectedConfig[canId]?.timeout_ms}
                                                style={{ width: '10%', border: timeoutErrors ? '1px solid red' : undefined }}
                                                onChange={(e) => updateConfig(canId, 'timeout_ms', parseInt(e.target.value, 10))}
                                            />
                                        </Form.Item>
                                    </Form>
                                </Tabs.TabPane>
                            )
                        })}
                    </Tabs>
                </Collapse.Panel>
            </Collapse>
            <Modal
                title="Rename Tab"
                open={isEditing}
                onOk={handleRenameTab}
                onCancel={() => {
                    setEditingTabId(null);
                    setIsEditing(false);
                }}
            >
                <Input
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                    placeholder="Enter new tab name"
                />
            </Modal>
        </>
    );
}

import { Tabs, Form, Dropdown, Button, Space, Table, Input, Modal, Collapse, Tooltip } from 'antd';
import type { TableColumnsType } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { EditOutlined, ExclamationOutlined } from '@ant-design/icons';

export default  function Conf20RulesField({
    onChange,
    formData,
    errorSchema,
    formContext
    }   :   {
    formData: any,
    onChange: (newFormData: any) => void,
    errorSchema: any,
    formContext: any
    })
{
    const rules: Array<{ [key: string]: any }> = Array.isArray(formData) ? formData : [];

    const [selectedDescription, setselectedDescription] = useState<{ [key: number]: string }>({});
    const [selectedCanId, setSelectedCanId] = useState<{ [key: number]: string }>({});
    const [selectedDlc, setSelectedDlc] = useState<{ [key: number]: number }>({});

    const [editingTabId, setEditingTabId] = useState<string | null>(null);
    const [newTabName, setNewTabName] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const canMessageConfig = useMemo(() => formContext?.can_message_config || {}, [formContext]);
    const canIdItems = Object.keys(canMessageConfig).map(canId => ({
        label: canId,
        key: canId,
    }));

    useEffect(() => {
        if (formData) {
            formData.forEach((item: any, index: number) => {
                setselectedDescription(prevState => ({
                    ...prevState,
                    [index]: item.description || '',
                }));
                setSelectedCanId(prevState => ({
                    ...prevState,
                    [index]: item.message_id || '',
                }));

                const matchingMessage = canMessageConfig[item.message_id];
                if (matchingMessage) {
                    const newDlc = matchingMessage.dlc;

                    setSelectedDlc(prevState => ({
                        ...prevState,
                        [index]: newDlc,
                    }));

                    const updatedValue = [...rules];

                    ['mask', 'range_low', 'range_high'].forEach(maskKey => {
                        const currentMask = updatedValue[index][maskKey] || [];
                        if (currentMask.length > newDlc) {
                            updatedValue[index][maskKey] = currentMask.slice(0, newDlc);
                        } else if (currentMask.length < newDlc) {
                            updatedValue[index][maskKey] = [
                                ...currentMask,
                                ...Array(newDlc - currentMask.length).fill('0x00'),
                            ];
                        }
                    });

                    onChange(updatedValue);
                }
            });
        }
    }, [formContext]);// eslint-disable-line react-hooks/exhaustive-deps

    const updateMask = (key: string, functionalityIndex: number, colIndex: number, inputValue: string) => {
        const updatedValue = [...rules];
        const updatedRule = { ...updatedValue[functionalityIndex] };

        updatedRule[key] = updatedRule[key].map((val: string, index: number) =>
            index === colIndex ? inputValue : val
        );

        updatedValue[functionalityIndex] = updatedRule;
        onChange(updatedValue);
    };

    const updateCanId = (index: number, canId: string) => {
        const updatedValue = [...rules];
        updatedValue[index].message_id = canId;
        onChange(updatedValue);
    };

    const handleCanIdSelect = (index: number, canId: string) => {
        setSelectedCanId(prevState => ({
            ...prevState,
            [index]: canId,
        }));
        updateCanId(index, canId);

        const matchingMessage = canMessageConfig[canId];
        if (matchingMessage) {
            const newDlc = matchingMessage.dlc;
            setSelectedDlc(prevState => ({
                ...prevState,
                [index]: newDlc,
            }));

            const updatedValue = [...rules];

            ['mask', 'range_low', 'range_high'].forEach(maskKey => {
                if (updatedValue[index][maskKey]) {
                    const currentMask = updatedValue[index][maskKey];
                    if (currentMask.length > newDlc) {
                        updatedValue[index][maskKey] = currentMask.slice(0, newDlc);
                    } else if (currentMask.length < newDlc) {
                        updatedValue[index][maskKey] = [
                            ...currentMask,
                            ...Array(newDlc - currentMask.length).fill('0x00'),
                        ];
                    }
                }
            });
            onChange(updatedValue);
        }
    };
    const handleAddTab = () => {
        const newFunctionality = {
        description:'Rename tab!',
        message_id: '',
        mask: [],
        range_low: [],
        range_high: [],
        };
        const updatedValue = [...rules, newFunctionality];
        onChange(updatedValue);
    };

    const reindexState = (state: { [key: number]: any }, index: number) =>
        Object.keys(state)
            .filter(key => Number(key) !== index)
            .reduce((acc, key, i) => {
                acc[i] = state[Number(key)];
                return acc;
            }, {} as { [key: number]: any });

    const handleDeleteTab = (index: number) => {
        const tabDescription = selectedDescription[index];
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete tab ${tabDescription} ?`,
            maskClosable: true,
            onOk: () => {
                const updatedRules = rules.filter((_, i) => i !== index);
                onChange(updatedRules);

                setselectedDescription(prev => {
                    const newState = { ...prev };
                    delete newState[index];
                    return reindexState(newState, index);
                });

                setSelectedCanId(prev => {
                    const newState = { ...prev };
                    delete newState[index];
                    return reindexState(newState, index);
                });

                setSelectedDlc(prev => {
                    const newState = { ...prev };
                    delete newState[index];
                    return reindexState(newState, index);
                });
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const renderMaskTable = (rule: any, index: number) => {
        const dlc = selectedDlc[index] || 0;
        const errors = (errorSchema as any)?.rules?.[String(index)] || {};

        const columns: TableColumnsType = [
            {
                title: 'Mask Type',
                dataIndex: 'maskType',
                fixed: 'left',
                width: 100,
                render: (text: string) => <span>{text}</span>,
            },
            ...Array(dlc).fill([]).map((_, colIndex) => ({
                title: `Byte ${colIndex + 1}`,
                width: 100,
                dataIndex: colIndex,
                render: (text: string, record: any) => {
                    const hasError = errors?.[record.key]?.[colIndex];
                    return(
                        <Input
                            value={record[colIndex]}
                            onChange={(e) => updateMask(record.key, index, colIndex, e.target.value)}
                            style={{ borderColor: hasError ? 'red' : undefined }}
                        />
                    )
                }
            })),
        ];

        const data = [
            { key: 'mask', maskType: 'Mask', ...rule.mask },
            { key: 'range_low', maskType: 'Range LOW', ...rule.range_low },
            { key: 'range_high', maskType: 'Range HIGH', ...rule.range_high },
        ];

        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                size="small"
                scroll={{ x: 'max-content' }}
            />
        );
    };

    const showRenameModal = (index: number) => {
        setEditingTabId(String(index));
        setNewTabName(selectedDescription[index]);
        setIsEditing(true);
    };

    const handleRenameTab = () => {
        if (editingTabId && newTabName) {
            const updatedValue = [...rules];
            updatedValue[Number(editingTabId)].description = newTabName;
            setselectedDescription(prevState => ({
                ...prevState,
                [Number(editingTabId)]: newTabName,
            }));
            onChange(updatedValue);
        }
        setIsEditing(false);
        setEditingTabId(null);
        setNewTabName('');
    };

    return (
        <>
            <Collapse>
                <Collapse.Panel header="Rules" key="1" >
                    <Tabs
                        type="editable-card"
                        onEdit={(key, action) => {
                        if (action === 'add') {
                            handleAddTab();
                        } else if (action === 'remove') {
                            handleDeleteTab(Number(key));
                        }
                        }}
                        addIcon="+"
                        items={rules.map((rule: any, index: number) => {
                            const hasErrors = !!(errorSchema as any)?.rules?.[String(index)];

                            return {
                                key: String(index),
                                label: (
                                    <span>
                                        <>
                                            <Tooltip title="Rename Tab">
                                                    <EditOutlined
                                                        onClick={() => showRenameModal(index)}
                                                        style={{ cursor: 'pointer', marginLeft: 5}}
                                                        size={50}
                                                    />
                                            </Tooltip>
                                            {'  '}{selectedDescription[index] || `Rename tab!`}{'  '}
                                            {hasErrors && (
                                                <ExclamationOutlined
                                                    style={{ color: 'red', fontSize: 15 }}
                                                />)
                                            }
                                        </>
                                    </span>
                                ),
                                closable: true,
                                children: (
                                    <Form layout="vertical">
                                        <Form.Item label="Message ID">
                                            <Dropdown
                                            menu={{
                                                items: canIdItems,
                                                selectable: true,
                                                onClick: (e) => handleCanIdSelect(index, e.key),
                                            }}
                                            >
                                            <Button>
                                                <Space>{selectedCanId[index] || 'Select CAN ID'}</Space>
                                            </Button>
                                            </Dropdown>
                                        </Form.Item>
                                        {renderMaskTable(rule, index)}
                                    </Form>
                                ),
                            }
                        })}
                    />
                </Collapse.Panel>
            </Collapse>
            <Modal
                title="Rename Tab"
                open={isEditing}
                onOk={handleRenameTab}
                onCancel={() => {
                    setIsEditing(false);
                    setEditingTabId(null);
                }}
            >
                <Input
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                />
            </Modal>
        </>
    );
}

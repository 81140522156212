import Conf10CanMessageConfigField from "Components/CONF_1_0_Modal/CustomWidgets/CanMessageConfig";
import Conf10FunctionalityConfigField from "Components/CONF_1_0_Modal/CustomWidgets/FunctionalityConfig";
import Conf10CanBusConfigField from "Components/CONF_1_0_Modal/CustomWidgets/CanBusConfig";
import Conf10FunctionalityListField from "./CustomWidgets/FunctionalityList";
import { FieldProps } from "@rjsf/utils";

export default function CONF_1_0_Modal (props: FieldProps) {
    const { formData, onChange, errorSchema } = props;

    const handleInvalidConfig = (config: any) => {
        const invalidRule = [
            {
                mask: [null],
                mask_off: [null],
                mask_on: [null]
            }
        ];
        if (JSON.stringify(config) === JSON.stringify(invalidRule)) {
            return [];
        }

        return config;
    };

    const handleInvalidList = (list: any) => {
        const invalidRule = [undefined];
        if (JSON.stringify(list) === JSON.stringify(invalidRule)) {
            return [];
        }

        return list;
    };

    const handleCanBusConfigChange = (updatedCanBusConfig: any) => {
        const updatedFormData = {
            ...formData,
            can_bus_config: updatedCanBusConfig,
        };
        onChange(updatedFormData);
    };

    const handleCanMessageConfigChange = (updatedCanMessageConfig: any) => {
        const updatedFormData = {
            ...formData,
            can_message_config: updatedCanMessageConfig,
        };
        onChange(updatedFormData);
    };

    const handleFunctionalityConfigChange = (updatedFunctionalityConfig: any) => {
        const updatedFormData = {
            ...formData,
            functionality_config: updatedFunctionalityConfig,
        };
        onChange(updatedFormData);
    };

    const handleFunctionalityListChange = (updatedFunctionalityList: any) => {
        const updatedFormData = {
            ...formData,
            functionality_list: updatedFunctionalityList,
        };
        onChange(updatedFormData);
    };

  return (
    <div>
        <div style={{ marginBottom: '30px' }}>
            <Conf10CanBusConfigField
                formData={formData.can_bus_config}
                onChange={handleCanBusConfigChange}
                errorSchema={errorSchema}
            />
        </div>
        <div style={{ marginBottom: '30px' }}>
            <Conf10CanMessageConfigField
                formData={formData.can_message_config}
                onChange={handleCanMessageConfigChange}
                errorSchema={errorSchema}
            />
        </div>
        <div style={{ marginBottom: '30px' }}>
            <Conf10FunctionalityListField
                formData={handleInvalidList(formData.functionality_list)}
                onChange={handleFunctionalityListChange}
            />
        </div>
        <div style={{ marginBottom: '30px' }}>
            <Conf10FunctionalityConfigField
                formData={handleInvalidConfig(formData.functionality_config)}
                formContext={formData}
                onChange={handleFunctionalityConfigChange}
                errorSchema={errorSchema}
            />
        </div>
    </div>
  );
};
import Conf20RulesField from "Components/CONF_2_0_Modal/CustomWidgets/Rules";
import Conf20CanMessageConfigField from "Components/CONF_2_0_Modal/CustomWidgets/CanMessageConfig"
import Conf20FunctionalityControlField from "Components/CONF_2_0_Modal/CustomWidgets/FunctionalityControl";
import Conf20CanBusConfigField from "Components/CONF_2_0_Modal/CustomWidgets/CanBusConfig";
import { FieldProps } from "@rjsf/utils";

export default function CONF_2_0_Modal (props: FieldProps) {
    const { formData, onChange, errorSchema } = props;

    const handleInvalidRules = (rules: any) => {
        const invalidRule = [
            {
                mask: [null],
                range_high: [null],
                range_low: [null]
            }
        ];
        if (JSON.stringify(rules) === JSON.stringify(invalidRule)) {
            return [];
        }

        return rules;
    };

    const handleCanBusConfigChange = (updatedCanBusConfig: any) => {
        const updatedFormData = {
            ...formData,
            can_bus_config: updatedCanBusConfig,
        };
        onChange(updatedFormData);
    };

    const handleCanMessageConfigChange = (updatedCanMessageConfig: any) => {
        const updatedFormData = {
            ...formData,
            can_message_config: updatedCanMessageConfig,
        };
        onChange(updatedFormData);
    };

    const handleFunctionalityConfigChange = (updatedFunctionalityConfig: any) => {
        const updatedFormData = {
            ...formData,
            functionality_control: updatedFunctionalityConfig,
        };
        onChange(updatedFormData);
    };

    const handleRulesChange = (updatedRules: any) => {
        const updatedFormData = {
            ...formData,
            rules: updatedRules,
        };
        onChange(updatedFormData);
    };

    return (
        <div>
            <div style={{ marginBottom: '30px' }}>
                <Conf20CanBusConfigField
                    formData={formData.can_bus_config}
                    onChange={handleCanBusConfigChange}
                    errorSchema={errorSchema}
                />
            </div>
            <div style={{ marginBottom: '30px' }}>
                <Conf20CanMessageConfigField
                    formData={formData.can_message_config}
                    onChange={handleCanMessageConfigChange}
                    errorSchema={errorSchema}
                />
            </div>
            <div style={{ marginBottom: '30px' }}>
                <Conf20RulesField
                    formData={handleInvalidRules(formData.rules)}
                    formContext={formData}
                    onChange={handleRulesChange}
                    errorSchema={errorSchema}
                />
            </div>
            <div style={{ marginBottom: '30px' }}>
                <Conf20FunctionalityControlField
                    formData={formData.functionality_control}
                    formContext={formData}
                    onChange={handleFunctionalityConfigChange}
                />
            </div>
        </div>
    );
};
import { Tabs, Form, Dropdown, Button, Space, Table, Input, Modal, Collapse } from 'antd';
import type { TableColumnsType } from 'antd';
import type { MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { ExclamationOutlined } from '@ant-design/icons';

export default  function Conf10FunctionalityConfigField({
    onChange,
    formData,
    errorSchema,
    formContext
    }   :   {
    formData: any,
    onChange: (newFormData: any) => void,
    errorSchema: any,
    formContext: any
    })
{
    const functionalityConfig = formData ? formData : [];
    const [selectedFunctionality, setSelectedFunctionality] = useState<{ [key: number]: string }>({});
    const [selectedCanId, setSelectedCanId] = useState<{ [key: number]: string }>({});
    const [selectedDlc, setSelectedDlc] = useState<{ [key: number]: number }>({});

    const canMessageConfig = formContext?.can_message_config || [];
    const canIdItems = canMessageConfig.map((item: any) => ({
        label: item.can_id,
        key: item.can_id,
    }));
    const items: MenuProps['items'] = formContext?.functionality_list?.map((item: string, index: number) => ({
        label: item,
        key: item,
    })) || [];

    useEffect(() => {
        if(formContext && formContext.can_message_config)
        {
            formContext.functionality_config.forEach((item: string, index: number) => {
                if(functionalityConfig[index])
                setSelectedFunctionality(prevState => ({
                    ...prevState,
                    [index]: functionalityConfig[index].functionality || item,
                }));
            });
            formContext.functionality_config.forEach((item: any, index: number) => {
                if(functionalityConfig[index])
                setSelectedCanId(prevState => ({
                    ...prevState,
                    [index]: functionalityConfig[index].message_id || item.can_id,
                }));
            });
            formContext.functionality_config.forEach((functionality: any, index: number) => {
                const matchingMessage = formContext.can_message_config.find(
                    (msg: any) => msg.can_id === functionality.message_id
                );
                if (matchingMessage) {
                    console.log(matchingMessage.dlc)
                    setSelectedDlc(prevState => ({
                        ...prevState,
                        [index]: matchingMessage.dlc,
                    }));
                }
            });
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateMask = (key: string, functionalityIndex: number, colIndex: number, inputValue: string) => {
        const updatedValue = [...functionalityConfig];
        const updatedFunctionality = { ...updatedValue[functionalityIndex] };

        updatedFunctionality[key] = updatedFunctionality[key].map((val: string, index: number) =>
            index === colIndex ? inputValue : val
        );

        updatedValue[functionalityIndex] = updatedFunctionality;
        onChange(updatedValue);
    };

    const updateFunctionality = (index: number, value: string) => {
        const updatedValue = [...functionalityConfig];
        updatedValue[index].functionality = value;
        onChange(updatedValue);
    };

    const updateCanId = (index: number, canId: string) => {
        const updatedValue = [...functionalityConfig];
        updatedValue[index].message_id = canId;
        onChange(updatedValue);
    };

    const handleFunctionalitySelect = (index: number, value: string) => {
        setSelectedFunctionality(prevState => ({
            ...prevState,
            [index]: value,
        }));
        updateFunctionality(index, value);
    };

    const handleCanIdSelect = (index: number, canId: string) => {
        setSelectedCanId(prevState => ({
            ...prevState,
            [index]: canId,
        }));
        updateCanId(index, canId);

        const matchingMessage = formContext?.can_message_config.find(
            (msg: any) => msg.can_id === canId
        );

        if (matchingMessage) {
            const newDlc = matchingMessage.dlc;
            setSelectedDlc(prevState => ({
                ...prevState,
                [index]: newDlc,
            }));

            const updatedValue = [...functionalityConfig];

            ['mask', 'mask_off', 'mask_on'].forEach(maskKey => {
                if (updatedValue[index][maskKey]) {
                    const currentMask = updatedValue[index][maskKey];
                    if (currentMask.length > newDlc) {
                        updatedValue[index][maskKey] = currentMask.slice(0, newDlc);
                    } else if (currentMask.length < newDlc) {
                        updatedValue[index][maskKey] = [
                            ...currentMask,
                            ...Array(newDlc - currentMask.length).fill('0x00'),
                        ];
                    }
                }
            });

            onChange(updatedValue);
        } else {
            setSelectedDlc(prevState => ({
                ...prevState,
                [index]: 0
            }));
        }
    };
    const handleAddTab = () => {
        const newFunctionality = {
        functionality:'',
        message_id: '',
        mask: [],
        mask_off: [],
        mask_on: [],
        };
        const updatedValue = [...functionalityConfig, newFunctionality];
        onChange(updatedValue);
    };

    const reindexState = (state: { [key: number]: any }, index: number) =>
        Object.keys(state)
            .filter(key => Number(key) !== index)
            .reduce((acc, key, i) => {
                acc[i] = state[Number(key)];
                return acc;
            }, {} as { [key: number]: any });

    const handleDeleteTab = (index: number) => {
        const tabDescription = selectedFunctionality[index]
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete tab ${tabDescription} ?`,
            maskClosable: true,
            onOk: () => {
                const updatedValue = functionalityConfig.filter((_:any, i:any) => i !== index);
                onChange(updatedValue);

                setSelectedFunctionality(reindexState(selectedFunctionality, index));
                setSelectedCanId(reindexState(selectedCanId,index));
                setSelectedDlc(reindexState(selectedDlc,index));
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const renderMaskTable = (functionality: any, index: number) => {
        const dlc = selectedDlc[index] || 0;
        const errors = (errorSchema as any)?.functionality_config?.[String(index)] || {};

        const columns: TableColumnsType = [
            {
                title: 'Mask Type',
                dataIndex: 'maskType',
                fixed: 'left',
                width: 100,
                render: (text: string) => <span>{text}</span>,
            },
            ...Array(dlc).fill([]).map((_, colIndex) => ({
                title: `Byte ${colIndex + 1}`,
                width: 100,
                dataIndex: colIndex,
                render: (text: string, record: any) => {
                    const hasError = errors?.[record.key]?.[colIndex];
                    return(
                        <Input
                            value={record[colIndex]}
                            onChange={(e) => updateMask(record.key, index, colIndex, e.target.value)}
                            style={{ borderColor: hasError ? 'red' : undefined }}
                        />
                    )
                }
            })),
        ];

        const data = [
            { key: 'mask', maskType: 'Mask', ...functionality.mask },
            { key: 'mask_off', maskType: 'Mask Off', ...functionality.mask_off },
            { key: 'mask_on', maskType: 'Mask On', ...functionality.mask_on },
        ];

        return (
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                size="small"
                scroll={{ x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Collapse>
                <Collapse.Panel header="Functionality Control" key="1" >
                    <Tabs
                        type="editable-card"
                        onEdit={(key, action) => {
                        if (action === 'add') {
                            handleAddTab();
                        } else if (action === 'remove') {
                            handleDeleteTab(Number(key));
                        }
                        }}
                        addIcon="+"
                        items={functionalityConfig.map((functionality: any, index: number) => {
                            const hasErrors = !!(errorSchema as any)?.functionality_config?.[String(index)];

                            return {
                                key: String(index),
                                label: (
                                    <span>
                                        <>
                                            {'  '}{selectedFunctionality[index] || `Tab ${index + 1}`}{'  '}
                                            {hasErrors && (
                                                <ExclamationOutlined
                                                    style={{ color: 'red', fontSize: 15 }}
                                                />)
                                            }
                                        </>
                                    </span>
                                ),
                                closable: true,
                                children: (
                                    <Form layout="vertical">
                                    <Form.Item label="Functionality">
                                        <Dropdown
                                        menu={{
                                            items,
                                            selectable: true,
                                            onClick: (e) => handleFunctionalitySelect(index, e.key),
                                        }}
                                        >
                                        <Button>
                                            <Space>{selectedFunctionality[index] || 'Select Functionality'}</Space>
                                        </Button>
                                        </Dropdown>
                                    </Form.Item>
                                    <Form.Item label="Message ID">
                                        <Dropdown
                                        menu={{
                                            items: canIdItems,
                                            selectable: true,
                                            onClick: (e) => handleCanIdSelect(index, e.key),
                                        }}
                                        >
                                        <Button>
                                            <Space>{selectedCanId[index] || 'Select CAN ID'}</Space>
                                        </Button>
                                        </Dropdown>
                                    </Form.Item>
                                    {renderMaskTable(functionality, index)}
                                    </Form>
                                ),
                            }
                        })}
                    />
                </Collapse.Panel>
            </Collapse>
        </>
      );
}

import { Form, Button, Input, Collapse, List, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';


export default  function Conf10FunctionalityListField({
    onChange,
    formData,
    }   :   {
    formData: [],
    onChange: (newFormData: any) => void,
    })
{
    const functionalityList = formData ? formData : [];
    const [newFunctionalist, setNewFunctionalist] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setNewFunctionalist('');
    }, [formData]);

    const handleOk = () => {
        if (newFunctionalist.trim()) {
            const updatedList = [...functionalityList, newFunctionalist];
            onChange(updatedList);
            setNewFunctionalist('');
        }
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDelete = (item:string) => {
        const updatedList = functionalityList.filter((func: string) => func !== item)
        onChange(updatedList)
    }

    return (
        <Collapse>
            <Collapse.Panel header="Functionality List" key="0">
                <Button icon={<PlusOutlined />} onClick={() => setIsModalVisible(true) } />
                <List
                    bordered
                    dataSource={functionalityList}
                    renderItem={(item:string) => (
                        <List.Item>
                            {item}
                            <Button
                                color='danger'
                                title='Delete'
                                style={{marginLeft: 20}}
                                onClick={() => handleDelete(item)}
                            >
                                x
                            </Button>
                        </List.Item>
                    )}
                />
                <Modal title="Add Functionality" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Form layout="horizontal">
                        <Form.Item label="Functionality Name">
                            <Input
                                value={newFunctionalist}
                                onChange={(e) => setNewFunctionalist(e.target.value)}
                                placeholder="Enter functionality"
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </Collapse.Panel>
        </Collapse>
    );
}
import { Tabs, Form, Dropdown, Button, Space, Input, Modal, Collapse, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/menu/interface';
import { EditOutlined, ExclamationOutlined } from '@ant-design/icons';

export default  function Conf10CanMessageConfigField({
    onChange,
    formData,
    errorSchema
    }   :   {
    formData: any,
    onChange: (newFormData: any) => void,
    errorSchema: any
    })
{

    const canMessageConfig = formData ? formData : [];
    const [selectedCanId, setSelectedCanId] = useState<{ [key: number]: string }>({});
    const [selectedCanFormat, setSelectedCanFormat] = useState<{ [key: number]: string }>({});
    const [selectedDlc, setSelectedDlc] = useState<{ [key: number]: number }>({});
    const [selectedTimeout, setSelectedTimeout] = useState<{ [key: number]: number }>({});

    const [editingTabId, setEditingTabId] = useState<string | null>(null);
    const [newTabName, setNewTabName] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const canIdFormatItems: ItemType[] = [
        { label: 'standard', key: 'standard' },
        { label: 'extended', key: 'extended' },
      ];

    useEffect(() => {
        if(formData)
        {
            formData.forEach((item: any, index: number) => {
                if(canMessageConfig[index])
                {
                        setSelectedCanId(prevState => ({
                        ...prevState,
                        [index]: canMessageConfig[index].can_id || item.can_id,
                    }));
                        setSelectedCanFormat(prevState => ({
                        ...prevState,
                        [index]: canMessageConfig[index].can_id_format || item.can_id_format,
                    }));
                        setSelectedDlc(prevState => ({
                        ...prevState,
                        [index]: canMessageConfig[index].dlc || item.dlc,
                    }));
                        setSelectedTimeout(prevState => ({
                        ...prevState,
                        [index]: canMessageConfig[index].timeout_ms || item.timeout_ms,
                    }));
                }
            });
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const updateCanFormat = (index: number, value: string) => {
        const updatedValue = [...canMessageConfig];
        updatedValue[index].can_id_format = value;
        onChange(updatedValue);
    };

    const updateDlc = (index: number, value: number) => {
        const updatedValue = [...canMessageConfig];
        updatedValue[index].dlc = value;
        onChange(updatedValue);
    };

    const updateTimeout = (index: number, value: number) => {
        const updatedValue = [...canMessageConfig];
        updatedValue[index].timeout_ms = value;
        onChange(updatedValue);
    };

    const handleCanFormatSelect = (index: number, value: string) => {
        setSelectedCanFormat(prevState => ({
            ...prevState,
            [index]: value,
        }));
        updateCanFormat(index, value);
    };

    const handleAddTab = () => {
        const newCanMessageConfig = {
            can_id:'0x00',
            can_id_format: '',
            dlc: null,
            timeout_ms: null,
        };
        const updatedValue = [...canMessageConfig, newCanMessageConfig];
        onChange(updatedValue);

        setSelectedCanId(prevState => ({
            ...prevState,
            [updatedValue.length - 1]: '0x00',
        }));
    };

    const reindexState = (state: { [key: number]: any }, index: number) =>
        Object.keys(state)
            .filter(key => Number(key) !== index)
            .reduce((acc, key, i) => {
                acc[i] = state[Number(key)];
                return acc;
            }, {} as { [key: number]: any });

    const handleDeleteTab = (index: number) => {
        const tabDescription = selectedCanId[index];
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you really want to delete tab ${tabDescription} ?`,
            maskClosable: true,
            onOk: () => {
                const updatedValue = canMessageConfig.filter((_:any, i:any) => i !== index);
                onChange(updatedValue);

                setSelectedCanId(reindexState(selectedCanId, index));
                setSelectedCanFormat(reindexState(selectedCanFormat, index));
                setSelectedDlc(reindexState(selectedDlc, index));
                setSelectedTimeout(reindexState(selectedTimeout, index));
            },
            onCancel: () => {
                console.log('Cancel delete');
            },
        });
    };

    const showRenameModal = (index: number) => {
        setEditingTabId(String(index));
        setNewTabName(selectedCanId[index]);
        setIsEditing(true);
    };

    const handleRenameTab = () => {
        if (editingTabId !== null && newTabName.trim() !== "") {
            const index = Number(editingTabId);
            setSelectedCanId(prevState => ({
                ...prevState,
                [index]: newTabName,
            }));

            const updatedCanMessageConfig = [...canMessageConfig];
            updatedCanMessageConfig[index].can_id = newTabName;

            onChange(updatedCanMessageConfig);
        }
        setEditingTabId(null);
        setNewTabName("");
        setIsEditing(false);
    };


    return (
        <>
            <Collapse>
                <Collapse.Panel header="Can Message Config" key="1" >
                    <Tabs
                        type="editable-card"
                        onEdit={(key, action) => {
                            if (action === 'add') {
                                handleAddTab();
                            } else if (action === 'remove') {
                                handleDeleteTab(Number(key));
                            }
                        }}
                        addIcon="+"
                    >
                        {canMessageConfig.map((canMessage: any, index: number) => {
                            const hasErrors = !!(errorSchema as any)?.can_message_config?.[index];
                            const dlcErrors = !!(errorSchema as any)?.can_message_config?.[index]?.dlc;
                            const timeoutErrors = !!(errorSchema as any)?.can_message_config?.[index]?.timeout_ms;

                            return(
                                <Tabs.TabPane
                                    tab={
                                        <span>
                                            <>
                                                <Tooltip title="Rename Tab">
                                                        <EditOutlined
                                                            onClick={() => showRenameModal(index)}
                                                            style={{ cursor: 'pointer', marginLeft: 5 }}
                                                        />
                                                </Tooltip>
                                                {'  '}{selectedCanId[index]}{'  '}
                                                {hasErrors && (
                                                    <ExclamationOutlined
                                                        style={{ color: 'red', fontSize: 15 }}
                                                    />)
                                                }
                                            </>
                                        </span>
                                    }
                                    key={index}
                                    closable
                                >
                                    <Form layout="vertical">
                                        <Form.Item label="CAN ID format">
                                            <Dropdown
                                                menu={{
                                                    items: canIdFormatItems,
                                                    selectable: true,
                                                    onClick: (e) => handleCanFormatSelect(index, e.key),
                                                }}
                                            >
                                                <Button>
                                                    <Space>{selectedCanFormat[index] || 'Select CAN ID'}</Space>
                                                </Button>
                                            </Dropdown>
                                        </Form.Item>
                                        <Form.Item label="DLC">
                                        <Input
                                            type="number"
                                            value={selectedDlc[index]}
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value, 10);
                                                setSelectedDlc(prevState => ({
                                                    ...prevState,
                                                    [index]: value,
                                                }));
                                                updateDlc(index, value);
                                            }}
                                            style={{ width: '10%', border: dlcErrors ? '1px solid red' : undefined }}
                                        />
                                        </Form.Item>
                                        <Form.Item label="Timeout">
                                        <Input
                                            type="number"
                                            value={selectedTimeout[index]}
                                            onChange={(e) => {
                                                const value = parseInt(e.target.value, 10);
                                                setSelectedTimeout(prevState => ({
                                                    ...prevState,
                                                    [index]: value,
                                                }));
                                                updateTimeout(index, value);
                                            }}
                                            style={{ width: '10%', border: timeoutErrors ? '1px solid red' : undefined }}
                                        />
                                        </Form.Item>
                                    </Form>
                                </Tabs.TabPane>
                            )
                        })}
                    </Tabs>
                </Collapse.Panel>
            </Collapse>
            <Modal
                title="Rename Tab"
                open={isEditing}
                onOk={handleRenameTab}
                onCancel={() => {
                    setEditingTabId(null);
                    setIsEditing(false);
                }}
            >
                <Input
                    value={newTabName}
                    onChange={(e) => setNewTabName(e.target.value)}
                    placeholder="Enter new tab name"
                />
            </Modal>
        </>
    );
}

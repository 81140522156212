import { Form, Dropdown, Button, Space, Input, Checkbox, Collapse } from 'antd';
import { useEffect, useState } from 'react';
import { ItemType } from 'antd/es/menu/interface';

type InitialData_t = {
    baudrate_data_kbps: string,
    baudrate_normal_kbps: string,
    trcv_delay_offset_ms: number,
    fd_enable: boolean,
    brs_enable: boolean
}

export default  function Conf10CanBusConfigField({
    onChange,
    formData,
    errorSchema
    }   :   {
    formData: InitialData_t,
    onChange: (newFormData: any) => void,
    errorSchema: any
    })
{
    const [selectedConfig, setSelectedConfig] = useState<{ [key: string]: any }>({});

    const baudrateDataItems: ItemType[] = [
        { label: '500K', key: '500K' },
        { label: '833K', key: '833K' },
        { label: '1M', key: '1M' },
        { label: '1M5', key: '1M5' },
        { label: '2M', key: '2M' },
        { label: '3M', key: '3M' },
        { label: '4M', key: '4M' },
        { label: '5M', key: '5M' },
        { label: '6M7', key: '6M7' },
        { label: '8M', key: '8M' },
        { label: '10M', key: '10M' },
    ];

    const baudrateNormalItems: ItemType[] = [
        { label: '125K', key: '125K' },
        { label: '250K', key: '250K' },
        { label: '500K', key: '500K' },
        { label: '1000K', key: '1000K' }
    ];

    useEffect(() => {
        if(Object.keys(formData).length === 0)
        {
            const initialConfig : InitialData_t = {
                baudrate_data_kbps: '',
                baudrate_normal_kbps: '',
                trcv_delay_offset_ms: 0,
                fd_enable: false,
                brs_enable: false
            }
            onChange(initialConfig);
        }else{
            setSelectedConfig(formData);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedConfig(formData);
        // console.log('formData', formData);
    }, [formData]);

    const updateConfig = (key: string, value: any) => {
        const updatedConfig = {
            ...formData,
                [key]: value,
        };
        console.log('updatedConfig', updatedConfig);
        onChange(updatedConfig);
    };

    return (
            <Collapse>
                <Collapse.Panel header="Can Bus Config" key="0" >
                    <Form layout="horizontal">
                        <Form.Item label="Baudrate Data (kbps)">
                            <Dropdown
                                menu={{
                                    items: baudrateDataItems,
                                    selectable: true,
                                    onClick: (e) => updateConfig('baudrate_data_kbps', e.key),
                                }}
                            >
                                <Button>
                                    <Space>{selectedConfig.baudrate_data_kbps || 'Select Baudrate Data'}</Space>
                                </Button>
                            </Dropdown>
                        </Form.Item>
                        <Form.Item label="Baudrate Normal (kbps)">
                            <Dropdown
                                menu={{
                                    items: baudrateNormalItems,
                                    selectable: true,
                                    onClick: (e) => updateConfig('baudrate_normal_kbps', e.key),
                                }}
                            >
                                <Button>
                                    <Space>{selectedConfig.baudrate_normal_kbps || 'Select Baudrate Normal'}</Space>
                                </Button>
                            </Dropdown>
                        </Form.Item>
                        <Form.Item
                            label="Transceiver Delay (ms)"
                            validateStatus={errorSchema?.trcv_delay_offset_ms ? "error" : ""}
                        >
                            <Input
                                type="number"
                                value={selectedConfig.trcv_delay_offset_ms}
                                style={{
                                    width: '10%',
                                    border: errorSchema?.trcv_delay_offset_ms ? '1px solid red' : undefined
                                }}
                                onChange={(e) => updateConfig('trcv_delay_offset_ms', parseInt(e.target.value, 10))}
                            />
                        </Form.Item>
                        <Form.Item label="FD Enable">
                            <Checkbox
                                checked={selectedConfig.fd_enable}
                                onChange={(e) => updateConfig('fd_enable', e.target.checked)}
                            />
                        </Form.Item>
                        <Form.Item label="BRS">
                            <Checkbox
                                checked={selectedConfig.brs_enable}
                                onChange={(e) => updateConfig('brs_enable', e.target.checked)}
                            />
                        </Form.Item>
                    </Form>
                </Collapse.Panel>
            </Collapse>
    );
}